.loginContainer{
    background:#E6EFFD;
    display:flex;
    justify-content: center;
    height:100vh;
    padding: 50px 0px 600px 0px
}


.logoPosition{
    margin-bottom:40px
}


.loginFormContainer{
    background:white;
    border-radius:16px;
    padding:32px;
    margin:0px 0px 120px 0px;
    box-shadow:0px 0px 12px rgba(0, 0, 0, 0.08);
}

.loginFormContainer h1{
    font-size: 28px;
    font-weight:bold
}


.loginForm{
    margin:48px 0px
}

.loginForm label{
    font-weight:500
}