Th {
  font-weight: 600 !important;
  font-size: 12px !important;
  color: #000000 !important;
  text-transform: none !important;
}

.transactionTable {
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.08);
  margin: 40px 23px 40px 23px;
}

tr td {
  font-size: 14px;
  padding: 16px 20px;
}

.circleVerified {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  display: inline-block;
  background: #3cc13b;
}

.circleapproved {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  display: inline-block;
  background: #3cc13b;
  margin-top: 7px;
  margin-right:10px
}

.circlepending {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  display: inline-block;
  background: #ffbf02;
  margin-top: 7px;
  margin-right:10px
}

.circleSuccess {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  display: inline-block;
  background: #3cc13b;
  margin-top: 6px;
}

.circleDeclined {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  display: inline-block;
  background: #f03738;
  margin-top: 7px;
}

.circleUnverified {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  display: inline-block;
  background: #f03738;
  margin-top: 7px;
}

.kidCircle {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  display: inline-block;
  background: #005feb;
  margin-top: 7px;
}

.adultCircle {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  display: inline-block;
  background: #a05f9b;
  margin-top: 7px;
}

.youngster {
  color: #005feb;
}

.adult {
  color: #a05f9b;
}

.verified {
  background: #e8f8e8;
  color: #3cc13b;
  padding: 6px 8px;
  display: flex;
}

.completed {
  background: #e8f8e8;
  color: #3cc13b;
  padding: 10px 10px;
  display: flex;
  font-size: 13px;
  border-radius: 6px;
}

.approved {
  background: #e8f8e8;
  color: #3cc13b;
  padding: 10px 0px;
  display: flex;
  font-size: 13px;
  border-radius: 6px;
  justify-content: center;
}

.pending {
  background: #ffffcb;
  color: #ffbf02;
  padding: 10px 10px;
  display: flex;
  justify-content: center;
  font-size: 13px;
  border-radius: 6px;
}

.Pending {
  background: #ffffcb;
  color: #ffbf02;
  padding: 10px 10px;
  display: flex;
  justify-content: center;
  font-size: 13px;
  border-radius: 6px;
}


.declined {
  background: #feeaf3;
  color: #eb3f3f;
  padding: 10px 10px;
  display: flex;
  justify-content: center;
  font-size: 13px;
  border-radius: 6px;
}


.Joined{
    background: #e8f8e8;
    color: #3cc13b;
    padding: 10px 0px;
    display: flex;
    font-size: 13px;
    border-radius: 6px;
    justify-content: center;  
}

.disapproved {
  background: #feeaf3;
  color: #ff2000;
  padding: 10px 10px;
  display: flex;
  justify-content: center;
  font-size: 13px;
  border-radius: 6px;
}

.unverified {
  background: #feecec;
  color: #f03738;
  padding: 6px 8px;
  display: flex;
}

.kidText {
  color: #005feb;
}

.adultText {
  color: #a05f9b;
}
