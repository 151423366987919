.sideBarContainer{
    padding:40px 24px;
    box-shadow:  0px 8px 16px rgba(0, 0, 0, 0.08);
    height:400vh
}

.navItem{
    display:flex;
    padding:20px 12px;
    cursor: pointer;
}

.navMenuItem{
    display:flex;
    padding:20px 12px;
    cursor: pointer;
    justify-content: space-between;
}


.active{
    background:#E6EFFD;
    color:#005FEB;
    border-radius: 8px;
}

.navItemContainer{
    margin:50px 0px 0px 0px
}


.logOut{
    margin:90px 0px 0px 0px;
    color:black !important
}