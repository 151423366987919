Th{
    font-weight:600 !important;
    font-size:14px !important;
    color:#000000 !important;
    text-transform: none !important;
}


.customerTable{
    box-shadow:0px 8px 16px 0px rgba(0, 0, 0, 0.08);
    margin:40px 40px 40px 40px
}

tr td{
    font-size:14px;
    padding:16px 20px
}

.circleVerified{
height: 8px;
   width: 8px;
  border-radius: 50%;
  display: inline-block;
  background:#3CC13B;
  margin-top:7px
}

.circleUnverified{
    height: 8px;
       width: 8px;
      border-radius: 50%;
      display: inline-block;
      background:#F03738;
      margin-top:7px
    }
    
.kidCircle{
    height: 8px;
    width: 8px;
   border-radius: 50%;
   display: inline-block;
   background:#005FEB;
   margin-top:7px
}

.adultCircle{
    height: 8px;
    width: 8px;
   border-radius: 50%;
   display: inline-block;
   background:#A05F9B;
   margin-top:7px
}

.youngster{
    color:#005FEB
}

.adult{
    color:#A05F9B
}



.verified{
    background:#E8F8E8;
    color:#3CC13B;
    padding:6px 8px;
    width:110px
}


.unverified{
    background:#FEECEC;
    color:#F03738;
    padding:6px 8px;
    display:flex;
    width:110px
}

.suspended{
    background:#ffffcb;
    color:#ffd700;
    padding:6px 8px;
    display:flex;
    width:110px
}

.viewTabContainer{
    padding:16px 40px
}

.tableWidth{
    width:800px;
    max-width:800px;
    box-shadow:0px 8px 16px 0px rgba(0, 0, 0, 0.08);
    margin:36px auto;
    border-radius:10px
}


.scrollPagination{
    text-align: center;
    overflow-x: scroll;
    white-space: nowrap;
    padding:0px 28px 12px 28px 
}

.page{
    border:1px solid #0066f5;
    color:#0066f5;
    margin:0px 10px 10px 10px; 
    padding:6px 14px;
}


.active-page{
    background:#0066f5 !important;
    color:white;   
}