.dashboardHeading{
    padding:50px 90px 30px 30px;
    display:flex;
    justify-content: space-between;
}


.dashboardHeading h1{
    font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 32px;
}