Th{
    font-weight:600 !important;
    font-size:14px !important;
    color:#000000 !important;
    text-transform: none !important;
}


.customerTable{
    box-shadow:0px 8px 16px 0px rgba(0, 0, 0, 0.08);
    margin:40px 40px 40px 40px
}

tr td{
    font-size:14px;
    padding:16px 20px
}

.circleVerified{
height: 8px;
   width: 8px;
  border-radius: 50%;
  display: inline-block;
  background:#3CC13B;
  margin-top:7px
}

.circleUnverified{
    height: 8px;
       width: 8px;
      border-radius: 50%;
      display: inline-block;
      background:#F03738;
      margin-top:7px
    }
    
.kidCircle{
    height: 8px;
    width: 8px;
   border-radius: 50%;
   display: inline-block;
   background:#005FEB;
   margin-top:7px
}

.adultCircle{
    height: 8px;
    width: 8px;
   border-radius: 50%;
   display: inline-block;
   background:#A05F9B;
   margin-top:7px
}

.youngster{
    color:#005FEB
}

.adult{
    color:#A05F9B
}



.verified{
    background:#E8F8E8;
    color:#3CC13B;
    padding:6px 8px;
    width:110px
}


.unverified{
    background:#FEECEC;
    color:#F03738;
    padding:6px 8px;
    display:flex;
    width:110px
}

.suspended{
    background:#ffffcb;
    color:#ffd700;
    padding:6px 8px;
    display:flex;
    width:110px
}

.viewTabContainer{
    padding:16px 40px
}

.tableWidth{
    width:800px;
    max-width:800px;
    box-shadow:0px 8px 16px 0px rgba(0, 0, 0, 0.08);
    margin:36px auto;
    border-radius:10px
}

.kolosaveTable{
    width:900px;
    max-width:900px;
    box-shadow:0px 8px 16px 0px rgba(0, 0, 0, 0.08);
    margin:36px auto;
    border-radius:10px
}


.outlined{
    border:0.3px solid #4D5154
}


.active-chip {
    background:#E6EFFD;
    color:#005FEB;
    padding: 10px 10px;
    display: flex;
    font-size: 13px;
    border-radius: 6px;
  }

  .inactive-chip {
    background: #feeaf3;
    color: #ff2000;
    padding: 10px 10px;
    display: flex;
    font-size: 13px;
    border-radius: 6px;
  }

