@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');


body {
  margin: 0;
  width: 100%;
  height: 100%;
  scroll-behavior: smooth;
  overflow-x: hidden;
  overflow-y:scroll
}


h1,h2,h3,h4{
  font-family:"Inter"
}



p,div,span{
  font-family: "Inter";
}

code {
  font-family: "Inter";
}


.hiddenForm{
  display:none
}