.activeTab {
  color: #005feb;
  font-weight: 600;
  border-bottom: 2px solid;
}

.dashboardContent {
  padding: 20px 80px 30px 30px;
  display: flex;
  justify-content: space-between;
}

.overviewContainer {
  margin: 30px 90px 30px 30px;
  padding: 32px 20px 32px 32px;
  border-radius: 12px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.08);
}

.overviewContainer h2 {
  padding: 20px 0px 24px 0px;
  font-weight: 700;
  font-size: 20px;
}

.cardStyle {
  border: 1px solid #fafafa;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
  width: 228px;
  height: 108px;
  cursor: pointer;
}

.cardStyle h2 {
  padding: 0px;
  font-weight: 700;
  font-size: 17px;
  color: #005feb;
}


.cardGrid{
    display:grid;
    grid-template-columns:1fr 1fr 1fr ;
    grid-gap:20px
}


@media only screen and (min-width:1600px){
  .cardGrid{
    display:grid;
    grid-template-columns:auto auto auto auto;
    grid-gap:20px
}
}


@media only screen and (min-width:1900px){
  .cardGrid{
    display:grid;
    grid-template-columns:auto auto auto auto auto;
    grid-gap:20px
}
}